import React from "react"
import { graphql } from "gatsby"
import { getFixedGatsbyImage } from "gatsby-source-sanity"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import videoClip from "../video/videoClip.mp4"
import HeroContainer from "../components/heroContainer"
import MainCard from "../components/card/MainCard"
import { sanityConfig } from "../sanityConfig"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const About = ({ data }) => {
  const { pageContent } = data

  const fluidBanner =
    getFixedGatsbyImage(
      pageContent?._rawHeaderImage?.asset?._id,
      { maxWidth: 1200 },
      sanityConfig
    ) || data.heroImage.childImageSharp.fluid

  const heroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBanner.childImageSharp.fluid
  const heroProps = {
    fluid: fluidBanner,
    gradient: true,
  }
  const containerOneCardProps = [
    {
      button: {
        text: "Learn More",
        className: "text-white bg-quaternary",
      },
      container: {
        className: "text-black",
      },
      divider: {
        className: "bg-quaternary",
      },
      header: {
        text: "Welcome",
        hide: false,
      },
      text: [
        "Sioux Lookout and the beautiful First Nations in this area are waiting for you!",
        " Sioux Lookout Regional Physicians' Services Inc. welcomes you to our communities: from Sioux Lookout to Fort Severn, from Webequie to North Spirit Lake.",
        "When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family.",
        "We invite you to explore our website and find out all that Sioux Lookout and area has to offer you!",
      ],
      video: [videoClip, videoClip],
    },
  ]
  const containerTwoCardProps = [
    {
      button: {
        text: "Learn More",
        className: "text-white bg-quaternary",
      },
      container: {
        className: "text-black",
      },
      divider: {
        className: "bg-quaternary",
      },
      header: {
        text: "Welcome",
        hide: false,
      },
      text: [
        "Sioux Lookout and the beautiful First Nations in this area are waiting for you!",
        " Sioux Lookout Regional Physicians' Services Inc. welcomes you to our communities: from Sioux Lookout to Fort Severn, from Webequie to North Spirit Lake.",
        "When choosing to work in Northwestern Ontario, you are not only choosing your employer and career opportunities, you are choosing a way of life. If adventure, solitude in nature and the great outdoors at your fingertips are attractive to you, this is the place for you and your family.",
        "We invite you to explore our website and find out all that Sioux Lookout and area has to offer you!",
      ],
      images: [heroFluid, heroFluid, heroFluid],
    },
  ]

  return (
    <Layout>
      <SEO title="About Us" />
      <HeroContainer props={heroProps} />
      <BackgroundContainer bgFluid={secondFluid} className="py-10 px-5">
        {/* <PageTitle text="About Us" /> */}
        <MainCard
          className="text-secondary mt-10  "
          title={{ text: "About SLRPSI" }}
          headerTop="top-60"
        >
          <BlockContentPrimary blockData={pageContent._rawSlrpsiSection} />
        </MainCard>
        <MainCard
          className="text-secondary mt-10 top  "
          title={{ text: "About SLFNHA" }}
          headerTop="top-60"
        >
          <BlockContentPrimary blockData={pageContent._rawSlfnhaSection} />
        </MainCard>
      </BackgroundContainer>
    </Layout>
  )
}
export default About

export const query = graphql`
  query {
    pageContent: sanityAboutPage {
      title
      _rawSlrpsiSection(resolveReferences: { maxDepth: 10 })
      _rawSlfnhaSection(resolveReferences: { maxDepth: 10 })
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
    }

    heroImage: file(relativePath: { eq: "about_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBanner: file(
      relativePath: { eq: "kristjan-sverrisson-FjVBKyP95Ik-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 4000, grayscale: false) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
